<script lang="ts">
  import { navigate } from "@printlabels/utils/navigate";
  import { viewTypes } from "./viewTypes";
  import { _ } from "svelte-i18n";
  import { searchResult } from "./searchResult";
  import { flip } from "svelte/animate";
  import Preview from "./Preview.svelte";

  export let view: viewTypes = viewTypes.Large;
  export let searchValue: string = "";
</script>

<div
  class="grid gap-5 uhd:gap-y-24 grid-cols-1 grid-flow-dense {view ==
  viewTypes.Large
    ? 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 uhd:grid-cols-6'
    : 'sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 uhd:grid-cols-10'}"
>
  <div
    class="rounded-2xl bg-white min-h-0 min-w-0 cursor-pointer flex flex-col gap-2 text-base-100/50 items-center justify-center"
    on:click={() => {
      navigate("/new");
    }}
  >
    <svg
      width="30%"
      height="50%"
      viewBox="0 0 56 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.625 34.5938V40.0312H12.4375V34.5938H43.625ZM30.9375 21.3125V54.4375H25.1562V21.3125H30.9375Z"
        fill="#5E5E5E"
        fill-opacity="0.5"
      />
      <circle
        cx="28"
        cy="37"
        r="26.5"
        stroke="#5E5E5E"
        stroke-opacity="0.5"
        stroke-width="3"
      />
    </svg>
    {$_("designchooser.createnew")}
  </div>
  {#each searchValue ? $searchResult.filter((design) => design.name
          .toLowerCase()
          .includes(searchValue.toLowerCase())) : $searchResult as design (design.id)}
    <div animate:flip={{ duration: 200 }}>
      <Preview {design} {view} />
    </div>
  {/each}
</div>
