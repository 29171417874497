<script>
  export let size = "1em";
  export let width = size;
  export let height = size;
  export let color = "currentColor";
  export let viewBox = "0 0 18 14";
  export let ariaLabel = void 0;
  export let ariaHidden = void 0;
  export let title = void 0;
  export let desc = void 0;

  let className = void 0;
  export { className as class };
</script>

<svg
  {viewBox}
  {width}
  {height}
  class={className}
  aria-label={ariaLabel}
  aria-hidden={ariaHidden}
  >{#if desc}<desc>{desc}</desc>{/if}{#if title}<title>{title}</title>{/if}
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.30443 0H16.6249C17.3428 0 17.9293 0.586555 17.9293 1.30443C17.9293 2.0223 17.3428 2.60886 16.6249 2.60886H1.30443C0.586555 2.60886 0 2.0223 0 1.30443C0 0.586555 0.586555 0 1.30443 0ZM1.30443 11.3911H16.6249C17.3428 11.3911 17.9293 11.9777 17.9293 12.6956C17.9293 13.4134 17.3428 14 16.6249 14H1.30443C0.586555 14 0 13.4134 0 12.6956C0 11.9777 0.586555 11.3911 1.30443 11.3911ZM1.30443 5.69484H16.6249C17.3428 5.69484 17.9293 6.2814 17.9293 6.99927C17.9293 7.71714 17.3428 8.3037 16.6249 8.3037H1.30443C0.586555 8.3037 0 7.71714 0 6.99927C0 6.28286 0.586555 5.69484 1.30443 5.69484Z"
    fill={color}
  />
</svg>
