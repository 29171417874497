<script lang="ts">
  import { placableElements } from "$elements/interactableelements/elementLists";
  import type { Design } from "$api/types.gen";
  import { toast } from "$toasts/index";
  import type { LabelData } from "@printlabels/standards/types/labelData";
  import type { ElementInfoList } from "$elements/elementManager";

  export let design: Design | undefined;
  export let label: HTMLDivElement | undefined = undefined;
  export let elements: ElementInfoList;
  export let preset: LabelData = {
    height: 0,
    width: 0,
    color: "",
    margin_left: 0,
    margin_right: 0,
    margin_top: 0,
    margin_bottom: 0,
    rounding: 0,
  };

  $: if (elements && label) placeElements();

  export let placed = false;

  function placeElements() {
    if (placed) return;
    placed = true;
    try {
      for (let [_, settings] of Object.entries(elements)) {
        new placableElements[Number(settings.type)]![0](
          {},
          settings,
          undefined,
          label!,
        );
      }
    } catch (e) {
      // Tommy is on vacation, so no translations for now
      toast.addError(
        `Failed to place elements on ${
          design!.name
        } with error ${e}. Please report this issue to our support.`,
        5000,
      );
    }
  }
</script>

<div
  id={design?.id}
  class={`bg-white text-black absolute pointer-events-none ring-[1.5px] ring-black`}
  style={` background-color: ${preset.color}; height: ${preset.height}px; width: ${preset.width}px; transform-style: preserve-3d; border-radius: ${preset.rounding}px;`}
  bind:this={label}
>
  <div
    style={`height: ${
      preset.height + preset.margin_top + preset.margin_bottom
    }px; width: ${
      preset.width + preset.margin_left + preset.margin_right
    }px; left: ${-preset.margin_left}px; top: ${-preset.margin_top}px; transform: translateZ(-10px);`}
    class="absolute bg-[#fff4dc] ring-[1.5px] ring-base-100/50"
  ></div>
</div>
