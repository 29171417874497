<script>
  export let size = "1em";
  export let width = size;
  export let height = size;
  export let color = "currentColor";
  export let viewBox = "0 0 20 14";
  export let ariaLabel = void 0;
  export let ariaHidden = void 0;
  export let title = void 0;
  export let desc = void 0;

  let className = void 0;
  export { className as class };
</script>

<svg
  {viewBox}
  {width}
  {height}
  class={className}
  aria-label={ariaLabel}
  aria-hidden={ariaHidden}
  >{#if desc}<desc>{desc}</desc>{/if}{#if title}<title>{title}</title>{/if}
  <path
    d="M0 0H2V2H0V0ZM3 0H5V2H3V0ZM6 0H8V2H6V0ZM9 0H11V2H9V0ZM12 0H14V2H12V0ZM15 0H17V2H15V0ZM18 0H20V2H18V0ZM0 3H2V5H0V3ZM3 3H5V5H3V3ZM6 3H8V5H6V3ZM9 3H11V5H9V3ZM12 3H14V5H12V3ZM15 3H17V5H15V3ZM18 3H20V5H18V3ZM0 6H2V8H0V6ZM3 6H5V8H3V6ZM6 6H8V8H6V6ZM9 6H11V8H9V6ZM12 6H14V8H12V6ZM15 6H17V8H15V6ZM18 6H20V8H18V6ZM0 9H2V11H0V9ZM3 9H5V11H3V9ZM6 9H8V11H6V9ZM9 9H11V11H9V9ZM12 9H14V11H12V9ZM15 9H17V11H15V9ZM18 9H20V11H18V9ZM0 12H2V14H0V12ZM3 12H5V14H3V12ZM6 12H8V14H6V12ZM9 12H11V14H9V12ZM12 12H14V14H12V12ZM15 12H17V14H15V12ZM18 12H20V14H18V12Z"
    fill={color}
  />
</svg>
