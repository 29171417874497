<script context="module" lang="ts">
  import { _ } from "svelte-i18n";
  import {
    dateSorted,
    sortByCreated,
    sortByPrinted,
    sortByUpdated,
    sortType,
  } from "./sort/sortLabels";
  import { searchResult } from "./searchResult";
  import { viewTypes } from "./viewTypes";
  import { getUserDesigns } from "$api/design";
  import LargeBlockArrayIcon from "$icons/LargeBlockArrayIcon.svelte";
  import MagnifyingGlassIcon from "$icons/MagnifyingGlassIcon.svelte";
  import SmallBlockArrayIcon from "$icons/SmallBlockArrayIcon.svelte";
  import ThreeLinesIcon from "$icons/ThreeLinesIcon.svelte";
  import WatchDownIcon from "$icons/WatchDownIcon.svelte";
  import Dropdown from "$utils/Dropdown.svelte";
  import { supermarkt } from "@printlabels/utils/supermarkt";
  import NormalViews from "./NormalViews.svelte";
  import ListView from "./ListView.svelte";
  import { userLoaded } from "$api/user";
  import { designs } from "$winkelcentrum/designs";
</script>

<script lang="ts">
  let view: viewTypes = viewTypes.Large;
  let searchValue = supermarkt("");
  let dropdown: HTMLUListElement;

  $: if ($userLoaded) loadDesigns();

  async function loadDesigns() {
    const results = await getUserDesigns();

    $designs = results;

    $dateSorted = false;
    $searchResult = sortByUpdated();
  }

  enum Tabs {
    Recent = 0,
    All = 1,
    Shared = 2,
  }

  const sortingOptions = [
    {
      translation: "sortbycreated",
      name: "created",
      function: sortByCreated,
    },
    {
      translation: "sortbyupdated",
      name: "updated",
      function: sortByUpdated,
    },
    {
      translation: "sortbyprinted",
      name: "printed",
      function: sortByPrinted,
    },
  ];

  // why does this cause a no type overlap error????
  let tab: Tabs = Tabs.Recent;
</script>

<div class="flex w-full h-full flex-nowrap gap-4 p-4">
  <div class="flex flex-col">
    <div class="rounded-xl bg-base-100/10 pb-8 pt-6 w-56">
      <p class="text-semibold pl-4 pr-4 pb-2">{$_("designchooser.myLabels")}</p>
      <div class="flex flex-col gap-2 [&>*]:pl-12 [&>p]:whitespace-nowrap">
        <p
          class={`${
            tab === Tabs.Recent ? "text-primary" : "hover:bg-primary/10"
          }`}
        >
          {$_("designchooser.recent")}
        </p>
        <p
          class={`${tab == Tabs.All ? "text-primary" : "hover:bg-primary/10"}`}
        >
          {$_("designchooser.all")}
        </p>
        <p
          class={`${
            tab == Tabs.Shared ? "text-primary" : "hover:bg-primary/10"
          }`}
        >
          {$_("designchooser.shared")}
        </p>
      </div>
    </div>
  </div>
  <div
    class="rounded shadow-inner w-full h-[86vh] uhd:h-[86vh] bg-base-300 relative overflow-y-scroll overflow-x-hidden fancy-scrollbar scroll-smooth break-all p-5"
  >
    <div class="flex flex-row gap-2 mb-4">
      <button on:click={() => (view = viewTypes.Large)}>
        <LargeBlockArrayIcon />
      </button>

      <button on:click={() => (view = viewTypes.Small)}>
        <SmallBlockArrayIcon />
      </button>

      <button on:click={() => (view = viewTypes.List)}>
        <ThreeLinesIcon />
      </button>

      <div
        class="rounded-xl bg-white p-2 w-96 text-base-100/80 flex flex-row gap-2 items-center h-8"
      >
        <MagnifyingGlassIcon />
        <input
          type="text"
          class="focus:outline-none h-full w-full text-sm"
          placeholder="search..."
          bind:value={$searchValue}
        />
      </div>

      <Dropdown
        dropdownClasses="flex flex-col shadow-lg gap-2 bg-white pt-2 pb-2 [&>button]:pl-6 [&>li]:pl-6 [&>li]:pr-6 rounded-lg text-[1rem] z-[9999]"
        bind:dropdown
      >
        <div slot="outside" class="h-full flex items-center">
          <WatchDownIcon />
        </div>

        {#each sortingOptions as option}
          {#each [false, true] as reversed}
            <li
              class="flex flex-row w-full gap-2 items-center {$sortType.type ==
                option.name && $sortType.reversed == reversed
                ? 'text-primary'
                : 'hover:bg-base-200'} whitespace-nowrap"
              on:click={() => {
                $sortType = { type: option.name, reversed: reversed };
                $searchResult = option.function();
                dropdown.blur();
              }}
            >
              {$_(
                `designchooser.${option.translation}${
                  reversed ? "reversed" : ""
                }`,
              )}
            </li>
          {/each}
        {/each}
      </Dropdown>
    </div>
    {#if tab === Tabs.Recent && $userLoaded}
      {#if view != viewTypes.List}
        <NormalViews {view} searchValue={$searchValue} />
      {:else}
        <ListView searchValue={$searchValue} />
      {/if}
    {/if}
  </div>
</div>
