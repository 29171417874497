<script lang="ts">
  import TrashcanClosedIcon from "$icons/TrashcanClosedIcon.svelte";
  import { Units } from "@printlabels/standards/units";
  import { sortByDate } from "./sort/sortLabels";
  import { searchResult } from "./searchResult";
  import { _ } from "svelte-i18n";
  import { sleep } from "@printlabels/utils/sleep";
  import Menu from "$menu/BasicMenu.svelte";
  import OpenIcon from "$icons/OpenIcon.svelte";
  import CopyIcon from "$icons/CopyIcon.svelte";
  import { navigate } from "@printlabels/utils/navigate";
  import type { Design } from "$api/types.gen";
  import { copyDesign, deleteDesign } from "$api/design";
  import { pixelToMm } from "$convertors/measurementConverter";

  export let design: Design;
  export let selectedDesign = "";

  let showMenu = false;
  let pos = { x: 0, y: 0 };

  async function onRightClick(e: MouseEvent) {
    e.stopPropagation();
    if (showMenu) {
      showMenu = false;
      await sleep(100);
    }

    pos = { x: e.clientX, y: e.clientY };
    showMenu = true;
  }

  function closeMenu() {
    showMenu = false;
  }

  export function click() {
    navigate(`/designs/${design.id}`);
  }

  async function copy() {
    await copyDesign(design.id, design.name);
    $searchResult = sortByDate();
  }

  $: width = pixelToMm(design?.preset?.width ?? 1).toFixed(2);
  $: height = pixelToMm(design?.preset?.height ?? 1).toFixed(2);
</script>

{#if showMenu}
  <Menu {...pos} on:click={closeMenu} on:clickoutside={closeMenu}>
    <ul
      class="flex flex-col gap-2 bg-white w-56 pt-2 pb-2 [&>button]:pl-6 [&>li]:pl-6 rounded-lg text-[1rem]"
      style={`z-index: 5555`}
    >
      <button
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
        on:click={click}
      >
        <OpenIcon />
        {$_("designchooser.open")}
      </button>

      <li
        on:click={copy}
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
      >
        <CopyIcon />
        {$_("designchooser.duplicate")}
      </li>

      <div class="bg-base-100/5 w-full h-[2px]" />

      <button
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
        on:click={async () => {
          closeMenu();
          selectedDesign = "";
          await deleteDesign(design?.id ?? "");
          $searchResult = sortByDate();
        }}
      >
        <TrashcanClosedIcon />
        {$_("designchooser.delete")}
      </button>
    </ul>
  </Menu>
{/if}
<button
  class="w-full {selectedDesign === design.id
    ? 'bg-primary/10'
    : 'hover:bg-base-100/20'} flex flex-row flex-nowrap gap-2 items-center [&>*]:text-left [&>*]:truncate [&>*]:text-sm p-1"
  on:click={() => {
    selectedDesign = design.id;
  }}
  on:dblclick={() => navigate(`/designs/${design.id}`)}
  on:contextmenu|preventDefault={onRightClick}
>
  <div class="w-[40%]">
    {design.name}
  </div>
  <div class="w-[15%]">
    {width}{Units.mm}
  </div>
  <div class="w-[15%]">
    {height}{Units.mm}
  </div>
  <div class="w-[15%]">
    {new Date(design.updated).toLocaleDateString()}
  </div>
  <div class="w-[15%]">
    {new Date(design.printed).toLocaleDateString()}
  </div>
</button>
