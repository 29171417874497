<script lang="ts">
  import SimplePreview from "./SimplePreview.svelte";
  import type { LabelData } from "@printlabels/standards/types/labelData";
  import { writable, type Writable } from "svelte/store";
  import { scaleToParent } from "@printlabels/utils/dom/scaleToContainer";
  import { centerToContainer } from "@printlabels/utils/dom/centerToContainer";
  import type { Design } from "$api/types.gen";
  import { onMount, tick } from "svelte";

  export let design: Design | undefined;
  let labelSettings: Writable<LabelData | undefined> = writable(undefined);
  let label: HTMLDivElement | undefined = undefined;
  let rendered = false;
  let container: HTMLDivElement | undefined = undefined;

  async function scaleLabel() {
    await tick();
    const scaling = scaleToParent(label!, container!);
    const { top, left } = centerToContainer(label!, container!);
    label!.style.top = top + "px";
    label!.style.left = left + "px";
    label!.style.transform = `scale(${scaling * 0.8})`;
  }

  $: if (design && rendered) scaleLabel();

  onMount(() => {
    rendered = true;
  });
</script>

<div class="w-[25vw]">
  <div class="w-full aspect-[1.25/1] bg-white overflow-hidden rounded-xl">
    <div class="w-full h-full bg-primary/10 relative" bind:this={container}>
      <SimplePreview
        {design}
        {labelSettings}
        bind:label
        elements={design.elements}
        preset={design.preset}
      />
    </div>
  </div>
</div>
