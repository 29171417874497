<script>
  export let size = "1em";
  export let width = size;
  export let height = size;
  export let color = "currentColor";
  export let viewBox = "0 0 22 14";
  export let ariaLabel = void 0;
  export let ariaHidden = void 0;
  export let title = void 0;
  export let desc = void 0;

  let className = void 0;
  export { className as class };
</script>

<svg
  {viewBox}
  {width}
  {height}
  class={className}
  aria-label={ariaLabel}
  aria-hidden={ariaHidden}
  >{#if desc}<desc>{desc}</desc>{/if}{#if title}<title>{title}</title>{/if}
  <path
    d="M18.9992 10.8578H21.4277L17.9993 14L13.9994 10.8578H16.9993V0.000558082L18.9992 0V10.8578ZM6.99972 0.000558082C3.13987 0.000558082 0 3.13043 0 7.00028C0 10.8701 3.12987 14 6.99972 14C10.8596 14 13.9994 10.8701 13.9994 7.00028C13.9994 3.13043 10.8696 0.000558082 6.99972 0.000558082ZM6.99972 2.15047C9.66961 2.15047 11.8495 4.32039 11.8495 7.00028C11.8495 9.68017 9.67961 11.8501 6.99972 11.8501C4.31983 11.8501 2.14991 9.68017 2.14991 7.00028C2.14991 4.32039 4.31983 2.15047 6.99972 2.15047ZM5.99976 4.0004V7.69025L9.18963 9.53018L9.9396 8.23023L7.4997 6.82029V4.0004"
    fill={color}
  />
</svg>
