export function getOldestTime(dateString: string) {
  const date = new Date(dateString);
  const now = new Date();
  const years = now.getFullYear() - date.getFullYear();
  const months = now.getMonth() - date.getMonth();
  const days = now.getDate() - date.getDate();
  const hours = now.getHours() - date.getHours();
  const minutes = now.getMinutes() - date.getMinutes();
  const seconds = now.getSeconds() - date.getSeconds();

  if (years >= 1) return ["years", years];
  if (months >= 1) return ["months", months];
  if (days >= 1) return ["days", days];
  if (hours >= 1) return ["hours", hours];
  if (minutes >= 1) return ["minutes", minutes];
  return ["seconds", Math.abs(seconds)];
}
