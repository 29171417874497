<script lang="ts">
  import TwoArrows from "$labelcatalogue/TwoArrows.svelte";
  import { _ } from "svelte-i18n";
  import { searchResult } from "./searchResult";
  import ListItem from "./ListItem.svelte";
  import ListItemPreview from "./ListItemPreview.svelte";
  import { writable } from "svelte/store";
  import OpenIcon from "$icons/OpenIcon.svelte";
  import { navigate } from "@printlabels/utils/navigate";
  import {
    sortByHeight,
    sortByName,
    sortByPrinted,
    sortByUpdated,
    sortByWidth,
    sortType,
  } from "./sort/sortLabels";

  let selectedDesign = writable("");
  export let searchValue = "";
</script>

<div class="flex flex-col">
  <div class="flex flex-row justify-between">
    <div class="rounded-xl w-2/3 h-2/3 flex flex-col bg-white overflow-hidden">
      <div
        class="flex flex-row rounded-t-xl w-full bg-base-100/20 p-1 [&>*]:text-sm"
      >
        <button
          class="w-[40%] justify-start flex flex-row flex-nowrap gap-2 items-center whitespace-nowrap"
          on:click={() => {
            $sortType = {
              type: "name",
              reversed: $sortType.type == "name" ? !$sortType.reversed : false,
            };
            $searchResult = sortByName();
          }}
        >
          {$_("designchooser.filename")}
          <TwoArrows />
        </button>

        <button
          class="w-[15%] justify-start flex flex-row flex-nowrap gap-2 items-center whitespace-nowrap"
          on:click={() => {
            $sortType = {
              type: "width",
              reversed: $sortType.type == "width" ? !$sortType.reversed : false,
            };
            $searchResult = sortByWidth();
          }}
        >
          {$_("designchooser.width")}
          <TwoArrows />
        </button>

        <button
          class="w-[15%] justify-start flex flex-row flex-nowrap gap-2 items-center whitespace-nowrap"
          on:click={() => {
            $sortType = {
              type: "height",
              reversed:
                $sortType.type == "height" ? !$sortType.reversed : false,
            };
            $searchResult = sortByHeight();
          }}
        >
          {$_("designchooser.height")}
          <TwoArrows />
        </button>

        <button
          class="w-[15%] justify-start flex flex-row flex-nowrap gap-2 items-center whitespace-nowrap"
          on:click={() => {
            $sortType = {
              type: "updated",
              reversed:
                $sortType.type == "updated" ? !$sortType.reversed : false,
            };
            $searchResult = sortByUpdated();
          }}
        >
          {$_("designchooser.lastedited")}
          <TwoArrows />
        </button>

        <button
          class="w-[15%] justify-start flex flex-row flex-nowrap gap-2 items-center whitespace-nowrap"
          on:click={() => {
            $sortType = {
              type: "printed",
              reversed:
                $sortType.type == "printed" ? !$sortType.reversed : false,
            };
            $searchResult = sortByPrinted();
          }}
        >
          {$_("designchooser.lastprinted")}
          <TwoArrows />
        </button>
      </div>

      <div class="flex flex-col overflow-y-scroll h-[60vh]">
        {#each searchValue ? $searchResult.filter((design) => design.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) : $searchResult as design (design.id)}
          <ListItem {design} bind:selectedDesign={$selectedDesign} />
        {/each}
      </div>
    </div>

    {#if $selectedDesign}
      <ListItemPreview
        design={$searchResult.find((x) => x.id === $selectedDesign)}
      />
    {/if}
  </div>

  <div class="flex flex-row h-[10vh] items-center">
    {#if $selectedDesign}
      <button
        on:click={() => navigate(`/designs/${$selectedDesign}`)}
        class="p-1 w-48 bg-white justify-center border-base-100 border-2 rounded-xl h-16 flex flex-row gap-2 items-center"
      >
        <OpenIcon size="2em" />
        {$_("designchooser.open")}
      </button>
    {/if}
  </div>
</div>
