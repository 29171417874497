<script lang="ts" context="module">
  import FilePlusOutline from "svelte-material-icons/FilePlusOutline.svelte";
  import SimplePreview from "./SimplePreview.svelte";
  import { navigate } from "@printlabels/utils/navigate";
  import { _ } from "svelte-i18n";
  import Menu from "$menu/BasicMenu.svelte";
  import { searchResult } from "./searchResult";
  import Pencil from "svelte-material-icons/Pencil.svelte";
  import WarningIcon from "$icons/WarningIcon.svelte";
  import CopyIcon from "$icons/CopyIcon.svelte";
  import OpenIcon from "$icons/OpenIcon.svelte";
  import TrashcanClosedIcon from "$icons/TrashcanClosedIcon.svelte";
  import { sortByDate } from "./sort/sortLabels";
  import { onMount, tick } from "svelte";
  import { viewTypes } from "./viewTypes";
  import Tooltip from "$utils/Tooltip.svelte";
  import DotsHorizontal from "svelte-material-icons/DotsHorizontal.svelte";
  import { getOldestTime } from "$convertors/timeConversion";
  import { formatTranslation } from "src/lib/text/translations";
  import { sleep } from "@printlabels/utils/sleep";
  import { scaleToParent } from "@printlabels/utils/dom/scaleToContainer";
  import { centerToContainer } from "@printlabels/utils/dom/centerToContainer";
  import type { Design } from "$api/types.gen";
  import { copyDesign, deleteDesign, updateDesign } from "$api/design";
  import type { LabelData } from "@printlabels/standards/types/labelData";
  import { designs } from "$winkelcentrum/designs";
  import type { ElementInfoList } from "$elements/elementManager";
</script>

<script lang="ts">
  export let view = viewTypes.Large;
  export let design: Design;
  export let titlecss = "";
  export let size = "w-full";

  export function click() {
    return openDesign(design!.id);
  }

  let elements: ElementInfoList;
  let preset: LabelData;

  let main: HTMLButtonElement;
  let label: HTMLDivElement;
  let title: HTMLSpanElement;
  let contenteditable = false;

  let hoverName = false;
  let hoverWarning = false;
  let warningElem: HTMLElement;

  let showMenu = false;
  let pos = { x: 0, y: 0 };

  async function onRightClick(e: MouseEvent) {
    e.stopPropagation();
    if (showMenu) {
      showMenu = false;
      await sleep(100);
    }

    pos = { x: e.clientX, y: e.clientY };
    showMenu = true;
  }

  function closeMenu() {
    showMenu = false;
  }

  async function scaleAndCenter() {
    await tick();
    if (!main || !label) return;
    const scaling = scaleToParent(label, main, 0) * 0.9;
    const coordinates = centerToContainer(label, main, 0);

    label.style.top = coordinates.top + "px";
    label.style.left = coordinates.left + "px";
    label.style.transform = `scale(${scaling})`;
  }

  function openDesign(id: string) {
    navigate(`/designs/${id}`);
  }

  async function parseLabel(content: Design) {
    elements = content.elements as ElementInfoList;
    preset = content.preset as LabelData;
  }

  function copy() {
    copyDesign(design!.id, design!.name);
  }

  async function makeEditable() {
    contenteditable = true;
    await tick();
    title.focus();
  }

  async function deleteOperation() {
    closeMenu();
    await deleteDesign(design?.id ?? "").then(() => {
      $designs = $designs.filter((d) => d.id !== design?.id);
    });
    $searchResult = sortByDate();
  }

  async function submitName() {
    contenteditable = false;
    if (design) {
      design.name = title.innerText;
      await updateDesign(design);
    }
  }

  let date = ["unknown", "unknown"];

  $: if (main != undefined) if (design != undefined) scaleAndCenter();
  $: designTitle = design?.name ?? "Untitled";
  $: versionCreated =
    design?.last_version_updated ?? import.meta.env.VITE_APP_VERSION;
  $: formattedTime = formatTranslation($_("designchooser.lastEdited"), {
    time: date[1]!,
    timeType: $_(`${date[0]}`),
  });

  onMount(async () => {
    await parseLabel(design!);
    date = getOldestTime(design!.updated) as string[];

    new ResizeObserver(() => scaleAndCenter()).observe(main);
  });
</script>

{#if showMenu}
  <Menu {...pos} on:click={closeMenu} on:clickoutside={closeMenu}>
    <ul
      class="flex flex-col gap-2 bg-white w-56 pt-2 pb-2 [&>button]:pl-6 rounded-lg text-[1rem]"
      style={`z-index: 5555`}
    >
      <button
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
        on:click={click}
      >
        <OpenIcon />
        {$_("designchooser.open")}
      </button>

      <button
        type="button"
        on:click={copy}
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
      >
        <CopyIcon />
        {$_("designchooser.duplicate")}
      </button>

      <button
        type="button"
        on:click|stopPropagation={makeEditable}
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
      >
        <Pencil />
        {$_("designchooser.edit")}
      </button>

      <div class="bg-base-100/5 w-full h-[2px]" />

      <button
        type="button"
        class="flex flex-row w-full gap-2 items-center hover:bg-base-200"
        on:click={deleteOperation}
      >
        <TrashcanClosedIcon />
        {$_("designchooser.delete")}
      </button>
    </ul>
  </Menu>
{/if}

<div
  class="relative rounded-2xl bg-white w-full"
  on:contextmenu|preventDefault={onRightClick}
  role="application"
>
  <button
    class="text-2xl text-base-100/50 absolute top-1 right-4 hover:bg-base-200 rounded p-0 m-0 z-[999]"
    on:click={onRightClick}
    type="button"
  >
    <DotsHorizontal />
  </button>

  <div class="flex flex-col gap-2 w-full items-center min-w-0 min-h-0 p-4">
    <Tooltip
      text={designTitle}
      position="-translate-y-full"
      bind:parentElement={title}
      bind:hovered={hoverName}
    />

    <button
      type="button"
      on:click={click}
      class="flex-grow {size} rounded relative overflow-hidden flex justify-center items-center aspect-video"
      bind:this={main}
    >
      {#if !design}
        <FilePlusOutline class="w-3/6 h-3/6 text-primary" />
      {:else}
        <SimplePreview {design} {elements} {preset} bind:label />
      {/if}
    </button>

    {#if contenteditable}
      <span
        role="form"
        bind:this={title}
        class="text-sm w-full text-base-100 truncate {titlecss} font-bold pl-2"
        on:keydown={(e) => {
          if (e.key == "Enter" || e.key == "Escape") title.blur();
        }}
        on:blur={submitName}
        {contenteditable}
      >
        {designTitle}
      </span>
    {:else}
      <p
        on:mouseenter={() => (hoverName = true)}
        on:mouseleave={() => (hoverName = false)}
        bind:this={title}
        class="text-sm w-full text-base-100 truncate {titlecss} font-bold pl-2"
      >
        {designTitle}
      </p>
    {/if}
    {#if view == viewTypes.Large}
      <span class="text-xs text-base-100 w-full text-nowrap truncate pl-2">
        {formattedTime}</span
      >
    {/if}
  </div>

  {#if versionCreated != import.meta.env.VITE_APP_VERSION}
    <Tooltip
      text={`${versionCreated} != ${import.meta.env.VITE_APP_VERSION}`}
      position="transform translate-y-full"
      bind:parentElement={warningElem}
      bind:hovered={hoverWarning}
    />

    <div
      class="absolute top-1 left-1"
      bind:this={warningElem}
      on:mouseenter={() => (hoverWarning = true)}
      on:mouseleave={() => (hoverWarning = false)}
      role="tooltip"
    >
      <WarningIcon color="#fbbd23" />
    </div>
  {/if}
</div>
