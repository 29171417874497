<script>
  export let size = "1em";
  export let width = size;
  export let height = size;
  export let color = "currentColor";
  export let viewBox = "0 0 19 14";
  export let ariaLabel = void 0;
  export let ariaHidden = void 0;
  export let title = void 0;
  export let desc = void 0;

  let className = void 0;
  export { className as class };
</script>

<svg
  {viewBox}
  {width}
  {height}
  class={className}
  aria-label={ariaLabel}
  aria-hidden={ariaHidden}
  >{#if desc}<desc>{desc}</desc>{/if}{#if title}<title>{title}</title>{/if}
  <path
    d="M0 4H4V0H0V4ZM0 9H4V5H0V9ZM5 9H9V5H5V9ZM10 9H14V5H10V9ZM5 4H9V0H5V4ZM10 0V4H14V0H10ZM15 9H19V5H15V9ZM0 14H4V10H0V14ZM5 14H9V10H5V14ZM10 14H14V10H10V14ZM15 14H19V10H15V14ZM15 0V4H19V0H15Z"
    fill={color}
  />
</svg>
