import { designs } from "$winkelcentrum/designs";
import { supermarkt } from "@printlabels/utils/supermarkt";

export let dateSorted = supermarkt(false);
export let sortType = supermarkt({ type: "updated", reversed: false });

export function sortByDate() {
  const sorttype = sortType.get();
  if (sorttype.type === "updated") return sortByUpdated();

  if (sorttype.type === "created") return sortByCreated();

  if (sorttype.type === "printed") return sortByPrinted();

  throw new Error("No valid sorting type given");
}

export function sortByUpdated() {
  let sorted = designs.get().sort((a, b) => {
    let dateA = new Date(a.updated);
    let dateB = new Date(b.updated);
    return dateB.getTime() - dateA.getTime();
  });

  return sortType.get().reversed ? sorted.reverse() : sorted;
}

export function sortByCreated() {
  let sorted = designs.get().sort((a, b) => {
    let dateA = new Date(a.created);
    let dateB = new Date(b.created);
    return dateB.getTime() - dateA.getTime();
  });

  return sortType.get().reversed ? sorted.reverse() : sorted;
}

export function sortByPrinted() {
  let sorted = designs.get().sort((a, b) => {
    let dateA = new Date(a.last_printed);
    let dateB = new Date(b.last_printed);
    return dateB.getTime() - dateA.getTime();
  });

  return sortType.get().reversed ? sorted.reverse() : sorted;
}

export function sortByHeight() {
  let sorted = designs.get().sort((a, b) => a.preset.height - b.preset.height);
  return sortType.get().reversed ? sorted.reverse() : sorted;
}

export function sortByWidth() {
  let sorted = designs.get().sort((a, b) => a.preset.width - b.preset.width);
  return sortType.get().reversed ? sorted.reverse() : sorted;
}

export function sortByName() {
  let sorted = designs.get().sort((a, b) => a.name.localeCompare(b.name));
  return sortType.get().reversed ? sorted.reverse() : sorted;
}
